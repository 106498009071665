.context-menu {
    position: absolute;
    z-index: 1000;

    .item {
        padding: 6px !important;
    }

    .item:hover {
        background-color: #eee !important;
    }
}