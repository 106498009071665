@font-face {
    font-family: 'fantasy icon';
    src: url('./fonts/fantasy-icon.eot');
    src: url('./fonts/fantasy-icon.eot?#iefix') format('embedded-opentype'),
         url('./fonts/fantasy-icon.woff') format('woff'),
         url('./fonts/fantasy-icon.ttf') format('truetype'),
         url('./fonts/fantasy-icon.svg#fantasy-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='fantasy-icon-']:before{
	display: inline-block;
   font-family: 'fantasy icon';
   font-style: normal;
   font-weight: bold;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.fantasy-icon-armor:before{content:'\0041';}
.fantasy-icon-axe:before{content:'\0042';}
.fantasy-icon-bomb:before{content:'\0043';}
.fantasy-icon-magic-book:before{content:'\0044';}
.fantasy-icon-arrow:before{content:'\0045';}
.fantasy-icon-castle:before{content:'\0046';}
.fantasy-icon-cauldron:before{content:'\0047';}
.fantasy-icon-coin:before{content:'\0048';}
.fantasy-icon-crossbow:before{content:'\0049';}
.fantasy-icon-crown:before{content:'\004a';}
.fantasy-icon-hourglass:before{content:'\004b';}
.fantasy-icon-healing-potion:before{content:'\004c';}
.fantasy-icon-hammer:before{content:'\004d';}
.fantasy-icon-gem:before{content:'\004e';}
.fantasy-icon-eye:before{content:'\004f';}
.fantasy-icon-dragon:before{content:'\0050';}
.fantasy-icon-door:before{content:'\0051';}
.fantasy-icon-dices:before{content:'\0052';}
.fantasy-icon-d20:before{content:'\0053';}
.fantasy-icon-food:before{content:'\0054';}
.fantasy-icon-key:before{content:'\0055';}
.fantasy-icon-full-helmet:before{content:'\0056';}
.fantasy-icon-flail:before{content:'\0057';}
.fantasy-icon-wand:before{content:'\0058';}
.fantasy-icon-fireball:before{content:'\0059';}
.fantasy-icon-map:before{content:'\005a';}
.fantasy-icon-money:before{content:'\0061';}
.fantasy-icon-cards:before{content:'\0062';}
.fantasy-icon-poison:before{content:'\0063';}
.fantasy-icon-tube:before{content:'\0064';}
.fantasy-icon-book:before{content:'\0065';}
.fantasy-icon-skull:before{content:'\0066';}
.fantasy-icon-horned-skull:before{content:'\0067';}
.fantasy-icon-sign:before{content:'\0068';}
.fantasy-icon-shield:before{content:'\0069';}
.fantasy-icon-emblem:before{content:'\006a';}
.fantasy-icon-scroll:before{content:'\006b';}
.fantasy-icon-notes:before{content:'\006c';}
.fantasy-icon-potion:before{content:'\006d';}
.fantasy-icon-flag:before{content:'\006e';}
.fantasy-icon-sword:before{content:'\006f';}
.fantasy-icon-ring:before{content:'\0070';}
.fantasy-icon-torch:before{content:'\0071';}
.fantasy-icon-chest:before{content:'\0072';}
.fantasy-icon-tree:before{content:'\0073';}
.fantasy-icon-goblet:before{content:'\0074';}
.fantasy-icon-helmet:before{content:'\0075';}
.fantasy-icon-hat:before{content:'\0076';}
.fantasy-icon-scepter:before{content:'\0077';}
.fantasy-icon-animal:before{content:'\0078';}